import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar-alert',
  standalone: true,
  imports: [MatButtonModule, MatIconModule, CommonModule],
  templateUrl: './snackbar-alert.component.html',
  styleUrl: './snackbar-alert.component.scss'
})
export class SnackbarAlertComponent implements OnInit{
  constructor(private snackBarRef: MatSnackBarRef<SnackbarAlertComponent>, 
    @Inject(MAT_SNACK_BAR_DATA) public data: any) {}

  ngOnInit(): void {
      //console.log(this.data, 'dataaaaaaaaaaaaaaaaaaa')
  }

  close(): void {
    this.snackBarRef.dismiss();
    // event.preventDefault();
  }

}
