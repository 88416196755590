import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarAlertComponent } from '../components/snackbar-alert/snackbar-alert.component';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  config = {
    duration: 5000,
    horizontalPosition: 'center',
    verticalPosition: 'top'
  }

  constructor(private _snackbar: MatSnackBar) { }

  getSnackbar(): MatSnackBar {
    return this._snackbar;
  }

  open() {
    return this._snackbar.open;
  }

  openFromComponent(data: any): void {
    this._snackbar.openFromComponent(SnackbarAlertComponent, {
      data,
     // duration: data?.type === 'success' ? 3000  : 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }
}
